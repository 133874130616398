import {
    TOGGLE_MOBILE_OPEN
} from './constants'

export const toggleMobileOpen = (value) => ({
    type: TOGGLE_MOBILE_OPEN,
    payload: !value
})


