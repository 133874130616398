import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'V 1.0.0 Copyright © '}
      {' '}
      {new Date().getFullYear()}
      {'.  '}
      <Link color="inherit" to='/'>
        ...Inicio
      </Link>
    </Typography>
  );
}

export default function StickyFooter() {

  let history = useHistory();
  const drawerWidth = history.location.pathname === '/products' ? 240 : 0

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'auto',
      position: 'relative'
    },
    main: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    footer: {
      padding: theme.spacing(2, 2),
      marginTop: '0',
      backgroundColor: "#7ddaff",
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
  }));



  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="sm" className='tc'>
          <Typography variant="body1">Distribuidora de productos Lacost S.A.S.</Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}