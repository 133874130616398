import React from 'react';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../../images/customers/', false, /\.(png|jpe?g|svg)$/));

const CustomerLogos = () => {
  return (
    <div className="bg-white pa4-ns pa3">
      <h2 className="f2-ns pt4 f3 center tc">Atentos a su necesidad</h2>
      <div className="tc center br4 ba bw2 b--light-blue pa3-ns">
        <img className="ma2 pa2-ns mw3 mw4-ns dib v-mid" src={images[0]} alt="Logo" />
        <img className="ma2 pa2-ns mw3 mw4-ns dib v-mid" src={images[1]} alt="Logo" />
        <img className="ma2 pa2-ns mw3 mw4-ns dib v-mid" src={images[2]} alt="Logo" />
      </div>
      <p className="f2-ns w-80 tc center">"Estamos comprometidos con tener clientes satisfechos"</p>
    </div>
  );
}
export default CustomerLogos;