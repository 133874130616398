import React from 'react';
import  headerImg from '../../images/icons/logo_lacost_800X334.png';

const Banner = () => {
    return (
        <div id="header" style={{ opacity: 0.6 }} className="bg-light-blue tc">
            <img src={headerImg} alt="distrilacost-Header" className="h4 h5-ns" />
        </div>
    );
}
export default Banner;
