import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import {Link} from 'react-router-dom';
import {Input, Link as MuiLink} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch } from "react-redux";
import {signIn} from './_redux/actions';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href="https://material-ui.com/">
        Distribuidora de productos Lacost S.A.S.
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container:{
    flexGrow:1
  }

}));



export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formInit = {
    email: "",
    password: ""
  };
  
  const [form, setForm] = useState(formInit);

  function onChangeField(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const submitSingIn = () => {
    console.log(form)
    dispatch(signIn(form))
    setForm(formInit)
  }

  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email_field"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={form.email}
            onChange={onChangeField}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type={values.showPassword ? 'text' : 'password'}
            id="password_field"
            autoComplete="current-password"
            value={form.password}
            onChange={onChangeField}
            InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>,
            }}
          />
{/*           <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          /> */}
          <Button
            type="reset"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitSingIn}
          >
            Iniciar sesión
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/password/reset/request" variant="body2">
                Olvidó su contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"No tienes una cuenta? Registrate!"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} className={classes.box}>
        <Copyright />
      </Box>
    </Container>
  );
}