import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from "react-redux";
import { createUser } from './_redux/actions'
import { nanoid } from 'nanoid'

const formInit = {
    name: "",
    surname: "",
    email: ""
}

const validationsInit = {
    noEdit: true,
    name: false,
    email: false,
    surname: false
}

export default function CreateDialog(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();

    const [formFields, setFormFields] = useState(formInit);
    const [validations, setValidations] = useState(validationsInit);

    const handleAdd = () => {
        const { name, email, surname} = formFields;

        if (name !== "" && surname !== "" && email !== "") {

            formFields['password'] = nanoid();
            dispatch(createUser(formFields));

            setValidations(validationsInit);
            setFormFields(formInit);
            handleClose();
        } else {
            let { ...currentValidations } = validationsInit

            currentValidations.name = name === "" ? true : false;
            currentValidations.surname = surname === "" ? true : false;
            currentValidations.email = email === "" ? true : false;

            setValidations(currentValidations);
        }
    }

    function onChangeField(e) {
        setFormFields({ ...formFields, [e.target.name]: e.target.value })
        setValidations({ ...validations, [e.target.name]: false, 'noEdit': false })
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
            <DialogTitle id="form-dialog-title">Crear un nuevo producto</DialogTitle>
            <DialogContent dividers>
                <form noValidate autoComplete="off">
                    <DialogContentText>
                        Aquí puede crear un nuevo producto.
                    </DialogContentText>
                    <TextField
                        error={validations.name}
                        required={validations.name}
                        name='name'
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Nombre"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <TextField
                        error={validations.surname}
                        required={validations.surname}
                        name='surname'
                        margin="normal"
                        id="surname"
                        label="Apellido"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <TextField
                        error={validations.email}
                        required={validations.email}
                        margin="normal"
                        name='email'
                        label="Email"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setValidations(validationsInit);
                    setFormFields(formInit);
                    handleClose();
                }} color="primary">
                    Cancelar
                    </Button>
                <Button disabled={validations.noEdit} onClick={handleAdd} color="primary">
                    Crear
                    </Button>
            </DialogActions>
        </Dialog>
    )
}
