import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import categories from '../../categories.json';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";

import { updateFilteredProducts } from '../../actions';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
        padding: 0,
    },
}));

export default function ChecboxFilter() {
    //selectors
    const products = useSelector(state => state.products.products)

    //dispatch
    const dispatch = useDispatch()

    //Checklist support
    const [checked, setChecked] = React.useState([]);
    
    const handleToggle = (item) => () => {
        const currentIndex = checked.indexOf(item);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        onFilterChange(newChecked);
    };
    //End Checklist support

    function onFilterChange(input) {
        var categories = [];
        var _filteredProducts = [];

        if (input)
            Object.entries(input).forEach(([key, item]) => {
                categories.push(item.value)
            });

        if (categories.length > 0 && products.length > 0)
            categories.forEach(element => {
                products.forEach(product => {
                    if (product.category === element) {
                        _filteredProducts.push(product)
                    }
                })
            });

        dispatch(updateFilteredProducts(_filteredProducts, input.length));
    }

    const classes = useStyles();

    return (
        categories.map((item, i) => {
            const labelId = `checkbox-list-label-${item}`;
            return (
                <ListItem className={classes.nested} key={i} role={undefined} dense button onClick={handleToggle(item)}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={checked.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${item.filter}`} />
                </ListItem>
            );
        })
    )
}