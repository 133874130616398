
export function authHeader() {
    const header = new Headers();
    header.append('authorization', window.sessionStorage.getItem('token'))
    return header
}


export function authHeaderJson() {
    const header = new Headers();
    header.append( 'Content-Type', 'application/json' );
    header.append('authorization', window.sessionStorage.getItem('token'))
    return header
}