import {
    SIGNIN_PENDING,
    SIGNIN_SUCCESS,
    SIGNIN_FAILED,

    VALIDATE_TOKEN_PENDING,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAILED,

    SIGNUP_PENDING,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,

    SIGNOUT_PENDING,
    SIGNOUT_SUCCESS,
    SIGNOUT_FAILED,

    SEND_RESET_EMAIL_PENDING,
    SEND_RESET_EMAIL_SUCCESS,
    SEND_RESET_EMAIL_FAILED,

    VALIDATE_RESET_TOKEN_PENDING,
    VALIDATE_RESET_TOKEN_SUCCESS,
    VALIDATE_RESET_TOKEN_FAILED,

    RESET_PASSWORD_PENDING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED
} from './constants'

const sessionInit = {
    user: {
        name: "",
        surname: "",
        role: ""
    },
    signedIn: false,
    error: null,
    pending: false
}




export const session = (state = sessionInit, action = {}) => {
    switch (action.type) {
        case SIGNIN_PENDING:
            return state;
        case SIGNIN_SUCCESS:
            return Object.assign({}, state, { user: action.payload, signedIn: true, error: null })
        case SIGNIN_FAILED:
            return Object.assign({}, state, { user: sessionInit, signedIn: false, error: action.payload })

        case SIGNUP_PENDING:
            return state;
        case SIGNUP_SUCCESS:
            return Object.assign({}, state, { user: action.payload, signedIn: true, error: null })
        case SIGNUP_FAILED:
            return Object.assign({}, state, { user: sessionInit.user, signedIn: false, error: action.payload })

        case SIGNOUT_PENDING:
            return state;
        case SIGNOUT_SUCCESS:
            return Object.assign({}, state, { user: sessionInit.user, signedIn: false, error: null })
        case SIGNOUT_FAILED:
            return Object.assign({}, state, { error: action.payload })

        case VALIDATE_TOKEN_PENDING:
            return state;
        case VALIDATE_TOKEN_SUCCESS:
            return Object.assign({}, state, { user: action.payload, signedIn: true, error: null })
        case VALIDATE_TOKEN_FAILED:
            return Object.assign({}, state, { user: state.user, signedIn: false, error: action.payload })

        case SEND_RESET_EMAIL_PENDING:
            return Object.assign({}, state, { pending: true})
        case SEND_RESET_EMAIL_SUCCESS:
            window.sessionStorage.setItem('email-reset', action.payload)
            return Object.assign({}, state, { pending: false})
        case SEND_RESET_EMAIL_FAILED:
            return Object.assign({}, state, { pending: false})

        case VALIDATE_RESET_TOKEN_PENDING:
            return Object.assign({}, state, { pending: true})
        case VALIDATE_RESET_TOKEN_SUCCESS:
            window.sessionStorage.setItem('token-reset', action.payload)
            return Object.assign({}, state, { pending: false})
        case VALIDATE_RESET_TOKEN_FAILED:
            return Object.assign({}, state, { pending: false})

        case RESET_PASSWORD_PENDING:
            return Object.assign({}, state, { pending: true})
        case RESET_PASSWORD_SUCCESS:
            window.sessionStorage.removeItem('email-reset')
            window.sessionStorage.removeItem('token-reset')
            return Object.assign({}, state, { pending: false})
        case RESET_PASSWORD_FAILED:
            return Object.assign({}, state, { pending: false})
        default:
            return state;
    }
}