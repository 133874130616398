import {

    SIGNIN_PENDING,
    SIGNIN_SUCCESS,
    SIGNIN_FAILED,

    SIGNUP_PENDING,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,

    SIGNOUT_PENDING,
    SIGNOUT_SUCCESS,
    SIGNOUT_FAILED,

    VALIDATE_TOKEN_PENDING,
    VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAILED,

    SEND_RESET_EMAIL_PENDING,
    SEND_RESET_EMAIL_SUCCESS,
    SEND_RESET_EMAIL_FAILED,

    VALIDATE_RESET_TOKEN_PENDING,
    VALIDATE_RESET_TOKEN_SUCCESS,
    VALIDATE_RESET_TOKEN_FAILED,

    RESET_PASSWORD_PENDING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED

} from './constants'

import createBrowserHistory from '../../../history'
import api, { apiHost } from '../../../api'

export function signIn(form) {
    return (dispatch) => {
        const history = createBrowserHistory;
        dispatch({ type: SIGNIN_PENDING });
        fetch(apiHost + api.signIn, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: form.email,
                password: form.password
            })
        })
            .then(res => {
                if (res.statusText !== 'OK') {
                    dispatch({ type: SIGNIN_FAILED, payload: res.statusText })
                    throw Error(res.statusText)
                } else {
                    res.json()
                        .then(data => {
                            window.sessionStorage.setItem('token', data.token)
                            window.sessionStorage.setItem('name', data.user.name)
                            window.sessionStorage.setItem('surname', data.user.surname)
                            window.sessionStorage.setItem('role', data.user.role)
                            dispatch({ type: SIGNIN_SUCCESS, payload: data.user })
                            //!PENDING TO REVIEW
                            history.push('/products/view');
                        })
                }
            }).catch(error => dispatch({ type: SIGNIN_FAILED, payload: error }))

    }

}


export const signUp = (form) => {
    const history = createBrowserHistory;
    return (dispatch) => {
        dispatch({ type: SIGNUP_PENDING });
        fetch(apiHost + api.signUp, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: form.name,
                email: form.email,
                surname: form.surname,
                password: form.password
            })
        }).then(response => {
            if (response.status !== 400) {
                response.json().then((data) => {
                    window.sessionStorage.setItem('token', data.token)
                    window.sessionStorage.setItem('name', data.user.name)
                    window.sessionStorage.setItem('surname', data.user.surname)
                    window.sessionStorage.setItem('role', data.user.role)
                    dispatch({ type: SIGNUP_SUCCESS, payload: data.user })
                    history.push('/products/view');

                }).catch(error => dispatch({ type: SIGNUP_FAILED, payload: error }));
            } else {
                console.log('Bad request')
                dispatch({ type: SIGNUP_FAILED, payload: response.statusText })
            }
        }).catch(error => dispatch({ type: SIGNUP_FAILED, payload: error }));
    }
}

export function signOut(dispatch) {
    dispatch({ type: SIGNOUT_PENDING })
    try {
        window.sessionStorage.setItem('token', "")
        window.sessionStorage.setItem('name', "")
        window.sessionStorage.setItem('surname', "")
        window.sessionStorage.setItem('role', "")
        dispatch({ type: SIGNOUT_SUCCESS })
    } catch (error) {
        dispatch({ type: SIGNOUT_FAILED, payload: error })
    }
}


export function validateToken(dispatch) {
    dispatch({ type: VALIDATE_TOKEN_PENDING })
    if (lookUpToken()) {
        var myHeaders = new Headers();
        myHeaders.append('authorization', window.sessionStorage.getItem('token'));

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(apiHost + api.validateToken, myInit)
            .then(res => {

                if (res.statusText !== 'OK')
                    throw new Error(res.status)
                else
                    res.json().then(data => {
                        const user = {
                            name: window.sessionStorage.getItem('name'),
                            surname: window.sessionStorage.getItem('surname'),
                            role: window.sessionStorage.getItem('role')
                        }
                        dispatch({ type: VALIDATE_TOKEN_SUCCESS, payload: user})
                    }).catch(error => console.log(error))
            }).catch(error => dispatch({ type: VALIDATE_TOKEN_FAILED, payload: error }))
    } else {
        dispatch({ type: VALIDATE_TOKEN_FAILED, payload: 'missing-token' })
    }
}

function lookUpToken() {
    const token = window.sessionStorage.getItem('token')
    if (token) {
        const tokenExist = token.toString().length > 0 ? true : false
        return tokenExist
    } else {
        return false
    }
}



export function sendTokenReset(form) {
    return (dispatch) => {
        const history = createBrowserHistory;
        dispatch({ type: SEND_RESET_EMAIL_PENDING });
        fetch(apiHost + api.sendTokenReset, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                mailto: form.email
            })
        })
            .then(res => {
              if (res.statusText !== 'OK') {
                    dispatch({ type: SEND_RESET_EMAIL_FAILED })
                    throw Error(res.statusText)
                } else {
                    res.json()
                        .then(res => {
                            dispatch({ type: SEND_RESET_EMAIL_SUCCESS, payload: form.email })
                            history.push('/password/reset/validate');
                        })
                }
            }).catch(error => dispatch({ type: SEND_RESET_EMAIL_FAILED }))
    }
}

export function validateResetToken(form) {
    return (dispatch) => {
        const history = createBrowserHistory;
        dispatch({ type: VALIDATE_RESET_TOKEN_PENDING });
        
        var myHeaders = new Headers();
        myHeaders.append('secret', form.secret);
        myHeaders.append('email', form.email);

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(apiHost + api.passwordReset, myInit)
            .then(res => {
              if (res.statusText !== 'OK') {
                    dispatch({ type: VALIDATE_RESET_TOKEN_FAILED })
                    throw Error(res.statusText)
                } else {
                    res.json()
                        .then(token => {
                            dispatch({ type: VALIDATE_RESET_TOKEN_SUCCESS, payload: token })
                            history.push('/password/reset/update');
                            window.location.reload();
                        })
                }
            }).catch(error => dispatch({ type: VALIDATE_RESET_TOKEN_FAILED }))
    }
}


export function resetPassword(form) {
    return (dispatch) => {
        const history = createBrowserHistory;
        dispatch({ type: RESET_PASSWORD_PENDING });
        
        var myHeaders = new Headers();
        myHeaders.append('authorization', form.token);
        myHeaders.append('Content-Type', 'application/json');
        var myInit = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                password: form.password
            }),
            mode: 'cors',
            cache: 'default'
        };

        fetch(apiHost + api.passwordReset, myInit)
            .then(res => {
              if (res.statusText !== 'OK') {
                    dispatch({ type: RESET_PASSWORD_FAILED })
                    throw Error(res.statusText)
                } else {
                    res.json()
                        .then(res => {
                            dispatch({ type: RESET_PASSWORD_SUCCESS })
                            history.push('/signin');
                        })
                }
            }).catch(error => dispatch({ type: RESET_PASSWORD_FAILED }))
    }
}


