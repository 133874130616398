import React from 'react';
import DropZone from 'react-dropzone';

import { useSelector, useDispatch } from 'react-redux';
import { setDropzoneFile } from './_redux/actions';

export default function Dropzone() {
    const dispatch = useDispatch();
    const fileName = useSelector(state => state.imageDropZone.fileName)

    const onDropFile = (files) => {
        dispatch(setDropzoneFile(files[0]))
    }

    return (
        <DropZone onDrop={onDropFile} >
            {({ getRootProps, getInputProps }) => (
                <section className="container mt2 w-100">
                    <div id='drop-z' {...getRootProps({ className: 'dropzone  ba bw2 b--dashed b--dark-gray br3 pointer bg-light-gray pa2' })}>
                        <input  {...getInputProps()} />
                        <ul className='mt1'>
                            {fileName?fileName:"Arrastra y suelta un archivo o has clic aqui!"}
                        </ul>
                    </div>
                </section>
            )}
        </DropZone>
    )
}