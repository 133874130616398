import React from 'react';
import HomeCarousel from '../Carousel';

const Slider = () => {
    return (
        <div className='tc bg-light-blue' >
            <HomeCarousel></HomeCarousel>
        </div>
    );
}
export default Slider;