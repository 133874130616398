import {

    GET_USERS_PENDING,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,


    CREATE_USER_PENDING,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILED,

    EDIT_USER_PENDING,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED,

    DELETE_USER_PENDING,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,

    SET_USER_ROLE_PENDING,
    SET_USER_ROLE_SUCCESS,
    SET_USER_ROLE_FAILED,

    EDIT_PENDING,
    EDIT_CONCLUDED

} from './constants'

import createBrowserHistory from '../../../history'
import api, { apiHost } from '../../../api'
import { authHeader, authHeaderJson } from '../../../services'


export const getUsers = () => (dispatch) => {
    dispatch({ type: GET_USERS_PENDING });

    var myInit = {
        method: 'GET',
        headers: authHeader(),
        mode: 'cors',
        cache: 'default'
    };

    fetch(apiHost + api.users, myInit)
        .then(res => {

            if (res.statusText !== 'OK')
                throw new Error(res.status)
            else
                res.json().then(users => {
                    console.log(users)
                    if (users === 'not found') {
                        dispatch({ type: GET_USERS_SUCCESS, payload: [] })
                    } else {
                        dispatch({ type: GET_USERS_SUCCESS, payload: users })
                    }
                }).catch(error => dispatch({ type: GET_USERS_FAILED, payload: error }))
        }).catch(error => dispatch({ type: GET_USERS_FAILED, payload: error }))

}

//#region EDITOR 
export function createUser(form) {
    const history = createBrowserHistory;
    return (dispatch, getState) => {
        const state = getState();

        if (state.userEditor.editPendig === false) {

            dispatch({ type: CREATE_USER_PENDING, payload: EDIT_PENDING });
            fetch(apiHost + api.user, {
                method: 'post',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    name: form.name,
                    email: form.email,
                    surname: form.surname,
                    password: form.password
                })
            }).then(response => {
                if (response.status !== 400) {
                    response.json().then(() => {
                        dispatch({ type: CREATE_USER_SUCCESS, payload: EDIT_CONCLUDED })
                        //TODO For optimization could add the new user to the users list instead of requesting all the users again

                        if (history.location.pathname === '/users/editor') {
                            dispatch(getUsers());
                        } else if (history.location.pathname === '/signup') {
                            history.goBack();
                        }
                    }).catch(error => dispatch({ type: CREATE_USER_FAILED, payload: error }));
                } else {
                    dispatch({ type: CREATE_USER_FAILED, payload: response.statusText })
                }
            }).catch(error => dispatch({ type: CREATE_USER_FAILED, payload: error }));
        } else {
            alert('Algo esta siendo creado o editado.')
        }

    }
}

export function editUser(form) {
    //! THE FIELDS NEEDS TO BE VALIDATED BEFORE SENDING THE REQUEST!
    return (dispatch, getState) => {
        const state = getState();
        const { id, name, email, surname, uid, phone } = form
        if (state.userEditor.editPendig === false) {
            dispatch({ type: EDIT_USER_PENDING, payload: EDIT_PENDING });
            fetch(apiHost + api.user, {
                method: 'put',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    id: id,
                    name: name,
                    email: email,
                    surname: surname,
                    uid: uid,
                    phone: phone,
                    hash: "missing password"
                })
            }).then(response => {
                if (response.status === 400) {
                    dispatch({ type: EDIT_USER_FAILED, payload: response.statusText })
                } else {
                    response.json().then(() => {
                        dispatch({ type: EDIT_USER_SUCCESS, payload: EDIT_CONCLUDED })
                        dispatch(getUsers())
                    }).catch(error => dispatch({ type: EDIT_USER_FAILED, payload: error }));
                }
            }).catch(error => dispatch({ type: EDIT_USER_FAILED, payload: error }));
        } else {
            alert('Hay un usuario siendo creado o editado en este momento.')
        }
    }
}

export function deleteUser(userId) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.userEditor.editPendig === false) {
            dispatch({ type: DELETE_USER_PENDING, payload: EDIT_PENDING });
            fetch(apiHost + api.user, {
                method: "DELETE",
                headers: authHeaderJson(),
                body: JSON.stringify({
                    id: userId
                })
            }).then(res => res.json())
                .then(data => {
                    if (data > 0) {
                        dispatch({ type: DELETE_USER_SUCCESS, payload: EDIT_CONCLUDED })
                        dispatch(getUsers());
                    }
                })
                .catch(error => dispatch({ type: DELETE_USER_FAILED, payload: error }))
        } else {
            alert('Hay un usuario siendo eliminado o editado en este momento.')
        }
    }
}

export const setUserRole = (role, id) => {
    return (dispatch) => {
        dispatch({ type: SET_USER_ROLE_PENDING });
        fetch(apiHost + api.userRole, {
            method: 'put',
            headers: authHeaderJson(),
            body: JSON.stringify({
                id: id,
                role: role
            })
        }).then(response => {
            if (response.statusText !== 'OK') {
                dispatch({ type: SET_USER_ROLE_FAILED, payload: response.statusText })
                dispatch(getUsers())
            } else {
                response.json().then(() => {
                    dispatch({ type: SET_USER_ROLE_SUCCESS })
                    dispatch(getUsers())
                }).catch();
            }
        }).catch(error => {
            dispatch({ type: SET_USER_ROLE_FAILED, payload: error })
            dispatch(getUsers())
        });
    }

}


