import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ProductsIcon from '@material-ui/icons/MenuBook';
import EditorIcon from '@material-ui/icons/Build';
import FilterIcon from '@material-ui/icons/FilterList';
import HomeIcon from '@material-ui/icons/Home';
import LockOpenIcon from '@material-ui/icons/LockOpen'
// import Products from './Products';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileOpen } from './ProductsView/redux/actions'
//imports for checklist
import ChecboxFilter from './ProductsView/CheckboxFilter';
import { useHistory } from "react-router-dom";


function ProductsLayout(props) {

  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },//!delete
    nested: {
      paddingLeft: theme.spacing(4),
      padding: 0,
    },
  }));

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const mobileOpen = useSelector(state => state.drawer.mobileOpen);



  const handleDrawerToggle = () => {
    dispatch(toggleMobileOpen(mobileOpen))
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  let history = useHistory();

  //Any array entry is an assigned set of paths, the order mattes, any new set of paths shoudl be added in tha last 
  //position and used with it's respective array index.
  const allowedPaths = [
    ['/products/view', '/products/editor', '/users/editor', '/products/view/', '/products/editor/', '/users/editor/'],
    ['/products/view', '/products/view/'],
  ];

  const adminRole = window.sessionStorage.getItem('role') === 'admin' ? true : false
  const contentRole = window.sessionStorage.getItem('role') === 'content' ? true : false

  const showRule = allowedPaths.map((path)=>path.includes(history.location.pathname))
  
  const showUsersEditorBtn = adminRole && showRule[0];
  const showProductsEditorBtn = (contentRole || adminRole) && showRule[0];
  const showCategoriesList = showRule[1];
  const showDrawer = showRule[0];

  


  const onClickHome = () => {
    history.push('/')
    handleDrawerToggle();
  }

  const onClickProducts = () => {
    history.push('/products/view')
    handleDrawerToggle();
  }

  const onClickProductsEditor = () => {
    history.push('/products/editor')
    handleDrawerToggle();
  }

  const onClickUsersEditor = () => {
    history.push('/users/editor')
    handleDrawerToggle();
  }

  const onClicSignIn = () => {
    history.push('/signin')
    handleDrawerToggle();
  }

  const logedIn = false


  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>


        <ListItemLink onClick={onClickHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItemLink>

        <Hidden xsUp={logedIn}>
          <ListItemLink onClick={onClicSignIn}>
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText primary="Ingresar" />
          </ListItemLink>
        </Hidden>
        
        <ListItemLink onClick={onClickProducts}>
          <ListItemIcon>
            <ProductsIcon />
          </ListItemIcon>
          <ListItemText primary="Productos" />
        </ListItemLink>
        <Hidden xsDown xsUp={!showProductsEditorBtn}>
          <ListItemLink onClick={onClickProductsEditor}>
            <ListItemIcon>
              <EditorIcon />
            </ListItemIcon>
            <ListItemText primary="Editar productos" />
          </ListItemLink>
        </Hidden>
        <Hidden xsDown xsUp={!showUsersEditorBtn}>
          <ListItemLink onClick={onClickUsersEditor}>
            <ListItemIcon>
              <EditorIcon />
            </ListItemIcon>
            <ListItemText primary="Editar usuarios" />
          </ListItemLink>
        </Hidden>
      </List>
      <Divider />
      <Hidden xsUp={!showCategoriesList}>
        <List>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <FilterIcon />
            </ListItemIcon>
            <ListItemText primary="Categorías" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <ChecboxFilter></ChecboxFilter>

            </List>
          </Collapse>
        </List>
      </Hidden>
    </div>
  );



  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open={showDrawer}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {/* <main className={classes.main}>
      <Products />
      </main> */}
    </div>
  );
}

ProductsLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default ProductsLayout;
