import axios from 'axios';

import {

    //CREATE-EDIT DIALOG
    DELETE_PRODUCT_PENDING,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILED,

    CREATE_PRODUCT_PENDING,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILED,

    EDIT_PRODUCT_PENDING,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILED,

    UPLOAD_IMAGE_PENDING,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILED,

    REMOVE_CLOUDINARY_IMAGE_PENDING,
    REMOVE_CLOUDINARY_IMAGE_SUCCESS,
    REMOVE_CLOUDINARY_IMAGE_FAILED,

    EDIT_PRODUCT_IMAGE_PENDING,
    EDIT_PRODUCT_IMAGE_SUCCESS,
    EDIT_PRODUCT_IMAGE_FAILED,

    EDIT_PENDING,
    EDIT_CONCLUDED,

    //DROPZONE
    FILE_ADDED_TO_DROPZONE,
    CLEANED_DROPZONE,
    //PRODUCT_IMAGE_URL_RETURNED

} from './constants'

import { requestProducts } from '../../../actions';

import api, {apiHost} from '../../../api'
import {authHeaderJson} from '../../../services'

//#region EDITOR 
export function createProduct(createForm) {
    return (dispatch, getState) => {
        const state = getState();

        if (state.editor.editPendig === false) {

            dispatch({ type: CREATE_PRODUCT_PENDING, payload: EDIT_PENDING });
            fetch(apiHost + api.product, {
                method: 'post',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    name: createForm.name,
                    description: createForm.description,
                    category: createForm.category,
                    url: null,
                    ref: createForm.ref,
                    units: createForm.units
                })
            }).then(response => {
                if (response.status !== 400) {
                    response.json().then(product => {
                        dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: EDIT_CONCLUDED })

                        //TODO For optimization could add the new product to the products list instead of requesting all the products again
                        dispatch(uploadImage(state.imageDropZone.file, product[0]));
                    }).catch(error => dispatch({ type: CREATE_PRODUCT_FAILED, payload: error }));
                } else {
                    dispatch({ type: CREATE_PRODUCT_FAILED, payload: response.statusText })
                }
            }).catch(error => dispatch({ type: CREATE_PRODUCT_FAILED, payload: error }));
        } else {
            alert('Hay un producto siendo creado o editado en este momento.')
        }

    }
}

export function editProduct(editForm) {
    //! THE FIELDS NEEDS TO BE VALIDATED BEFORE SENDING THE REQUEST!
    return (dispatch, getState) => {
        const state = getState();
        if (state.editor.editPendig === false) {
            dispatch({ type: EDIT_PRODUCT_PENDING, payload: EDIT_PENDING });
            fetch(apiHost + api.product, {
                method: 'put',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    id: editForm.id,
                    name: editForm.name,
                    description: editForm.description,
                    ref: editForm.ref,
                    category: editForm.category,
                    units: editForm.units
                })
            }).then(response => {
                if (response.status === 400) {
                    dispatch({ type: EDIT_PRODUCT_FAILED, payload: response.statusText })
                } else {
                    response.json().then((product) => {
                        dispatch({ type: EDIT_PRODUCT_SUCCESS, payload: EDIT_PENDING })
                        //TODO For optimization could update the edited product to the products list instead of requesting all the products again
                        dispatch(uploadImage(state.imageDropZone.file, product[0]));
                    }).catch(error => dispatch({ type: EDIT_PRODUCT_FAILED, payload: error }));
                }
            }).catch(error => dispatch({ type: EDIT_PRODUCT_FAILED, payload: error }));
        } else {
            alert('Hay un producto siendo creado o editado en este momento.')
        }
    }
}

export function uploadImage(file, product) {
    return (dispatch) => {
        //TODO this folder could be the category
        dispatch({ type: UPLOAD_IMAGE_PENDING, payload: EDIT_PENDING });
        const folder = "produtc_images"
        const transformation = "c_scale,h_200,w_200,f_jpg"

        if (file) {
            fetch(apiHost + api.productImageSignature, {
                method: 'post',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    folder: folder,
                    public_id: product.id,
                    transformation: transformation
                    
                })
            })
                .then(response => {
                    response.json().then(data => {
                        let formData = new FormData();
                        formData.append("signature", data.signature);
                        formData.append("api_key", "213659996144147");
                        formData.append("timestamp", data.timestamp);
                        formData.append("file", file);
                        formData.append("folder", folder);
                        formData.append("public_id", product.id);
                        formData.append("resource_type", "image");
                        formData.append("transformation", transformation);
                        
                        axios.post("https://api.cloudinary.com/v1_1/web-lacost-cloud/image/upload", formData)
                            .then(response => {
                                dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: EDIT_PENDING })
                                dispatch(editProductImage(response.data.secure_url, product))
                                dispatch(cleanDropZone());
                            }).catch(err => {
                                dispatch({ type: UPLOAD_IMAGE_FAILED, payload: err })
                                dispatch(cleanDropZone());
                            })
                    })
                }).catch(err => {
                    dispatch({ type: UPLOAD_IMAGE_FAILED, payload: err })
                    dispatch(cleanDropZone());
                })
        }
        else {
            dispatch({ type: UPLOAD_IMAGE_FAILED, payload: 'No immage added to the dropzone' })
            //!Desablig time out to check effect
            //setTimeout(() => {dispatch(requestProducts(product))}, 1000);
            dispatch(requestProducts(product))
        }
    }
}


export function destroyImage(productId) {
    return (dispatch) => {
        //TODO this folder could be the category
        dispatch({ type: REMOVE_CLOUDINARY_IMAGE_PENDING, payload: EDIT_PENDING });
        const folder = "produtc_images"

            fetch(apiHost + api.productImageSignature, {
                method: 'post',
                headers: authHeaderJson(),
                body: JSON.stringify({
                    public_id: folder+"/"+productId
                })
            })
                .then(response => {
                    response.json().then(data => {
                        let formData = new FormData();
                        formData.append("public_id", folder+"/"+productId);
                        formData.append("signature", data.signature);
                        formData.append("api_key", "213659996144147");
                        formData.append("timestamp", data.timestamp);
                        
                        axios.post("https://api.cloudinary.com/v1_1/web-lacost-cloud/image/destroy", formData)
                            .then(response => {
                                dispatch({ type: REMOVE_CLOUDINARY_IMAGE_SUCCESS, payload: response })
                            }).catch(err => {
                                dispatch({ type: REMOVE_CLOUDINARY_IMAGE_FAILED, payload: err })
                                console.log(err);
                            })
                    })
                }).catch(err => {
                    dispatch({ type: REMOVE_CLOUDINARY_IMAGE_FAILED, payload: err })
                })
        
    }
}

export function editProductImage(image_url, product) {
    return (dispatch) => {
        dispatch({ type: EDIT_PRODUCT_IMAGE_PENDING, payload: EDIT_PENDING });
        fetch(apiHost + api.productImage, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: product.id,
                url: image_url
            })
        }).catch(error => console.log(error)).then(response => {
            response.json().then((product) => {
                dispatch({ type: EDIT_PRODUCT_IMAGE_SUCCESS, payload: EDIT_CONCLUDED });
                dispatch(requestProducts(product))
            }).catch(error => console.log(error));

        }).catch(error => {
            dispatch({ type: EDIT_PRODUCT_IMAGE_FAILED, payload: error });
        });
    }
}


export function deleteProduct(productId) {
    return (dispatch, getState) => {

        //const state = getState();


        dispatch({ type: DELETE_PRODUCT_PENDING, payload: EDIT_PENDING });
        fetch(apiHost + api.product, {
            method: "DELETE",
            headers: authHeaderJson(),
            body: JSON.stringify({
                id: productId
            })
        }).then(res => res.json())
            .then(data => {
                if (data > 0) {
                    dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: EDIT_CONCLUDED })
                    dispatch(destroyImage(productId))
                }
            })
            .catch(error => dispatch({ type: DELETE_PRODUCT_FAILED, payload: error }))
    }
}

//#endregion

//#region DROPZONE

export const cleanDropZone = () => ({
    type: CLEANED_DROPZONE,
    payload: null
})

export const setDropzoneFile = (file) => ({
    type: FILE_ADDED_TO_DROPZONE,
    payload: file
})

//#endregion
