import React, { Component } from 'react';
//Components
import HomeButtons from './HomeButtons/HomeButtons';
import CustomerLogos from './CustomerLogos';
import Brands from './BrandsArea/Brands';
import HomeSlider from './Slider';
import Banner from './Banner';

class Home extends Component {
    render() {
        return (
            <div className="">
                <HomeSlider />
                <HomeButtons />
                <Banner />
                <Brands />
                <Banner />
                <CustomerLogos />
            </div>
        );
    }
}
export default Home;