import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import clsx from 'clsx';

//custom imports 
import noImage from '../../images/no_image.jpg'
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    minHeight: 430,
  },
  categoryText: {
  },
  nameText: {
    paddingTop: "1rem",
  },
  image:{
    width:"100%",
    height:250
  }
}));


export default function ProductCard(props) {
  const classes = useStyles();

  //custom
  const { name, imagePath } = props;
  var img = !imagePath ? noImage : imagePath;

  const style = "grow";
  const className = clsx(style, classes.root)
 

  return (
    <Card className={className}>
      {/* <CardMedia className={classes.media} title={"Has clic para ver detalles"}>
      </CardMedia> */}
      <img src={img} alt='product' className={classes.image}></img>
      <Divider></Divider>

      <CardContent >
        <Typography className={classes.categoryText}>
          {props.category}
        </Typography>
        <Typography variant="h6" className={classes.nameText}>
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
}