import {
    TOGGLE_MOBILE_OPEN
} from './constants'

const initialStateDrawer = {
    mobileOpen: false
}


export const drawer = (state = initialStateDrawer, action = {}) => {
    switch (action.type) {
        case TOGGLE_MOBILE_OPEN:
            return { ...state, mobileOpen: action.payload };
        default:
            return Object.assign({}, state)
    }
}