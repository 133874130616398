import {

    REQUEST_PRODUCTS_PENDING,
    REQUEST_PRODUCTS_SUCCESS,
    REQUEST_PRODUCTS_FAILED,
    PRODUCTS_FILTER_UPDATED,
    SEARCH_FIELD_UPDATED,
    UPDATE_PRODUCTS

} from './constants'

import api, {apiHost} from './api'
//import { updateSelectedProduct } from './components/Editor2/_redux/actions';

export const requestProducts = () => (dispatch) => {
    dispatch({ type: REQUEST_PRODUCTS_PENDING });
    fetch(apiHost+api.products)
        .then(res => res.json())
        .then(data => {
            if (data !== 'not found') {
                dispatch({ type: REQUEST_PRODUCTS_SUCCESS, payload: data })
            } else {
                dispatch({ type: REQUEST_PRODUCTS_SUCCESS, payload: [] })
            }
        })
        .catch(error => dispatch({ type: REQUEST_PRODUCTS_FAILED, payload: error }))
}

export const updateFilteredProducts = (products, selectedCategories) => (dispatch) => {
    const isFiltered = selectedCategories > 0 ? true : false;
    dispatch({ type: PRODUCTS_FILTER_UPDATED, payload: { products, isFiltered } });
}

export const updateSearchField = (value) => ({
    type: SEARCH_FIELD_UPDATED,
    payload: value
})

export function updateProducts(updatedProducts) {
    return(dispatch)=>{
        dispatch({ type: UPDATE_PRODUCTS, payload: updatedProducts });
    }
}


