import React from 'react';

const BrandLogo = ({image}) => {
    return (
        <img
            className="ma2 pa2-ns mw3 mw4-ns dib v-mid"
            src={image}
            alt="Logo"
        />
    );
}
export default BrandLogo;
