import React from 'react';
import BrandLogo from './BrandLogo';

//TODO: It should be refactor by creating a new class and doing if possible dependency inyection or
//TODO: just passing the reqiered images to the current class
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('../../../images/brands', false, /\.(png|jpe?g|svg)$/));


class Brands extends React.Component {
  render() {
    return (
      <div id="brand-area" className="bg-white pa4-ns pa3">
        <h2 className="f2-ns pt4 f3 center tc">Comercializamos calidad</h2>
        <div className="tc center br4 ba bw2 b--light-blue pa3-ns">
          {images.map((image, i) => 
            <BrandLogo
              key={i}
              image={image}
            />
          )}
        </div>
        <p className="f2-ns w-80 tc center">"Somos fabricantes y distribuidores de elementos de seguridad y productos para la industria"</p>
      </div>
    );
  }
}


export default Brands;