import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { setUserRole } from './_redux/actions';
import { useDispatch } from 'react-redux';





import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import roleListJson from '../../roleList.json'


export default function RowSelect(props) {
    const {rowData} = props

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const  onChangeField = (e, data) => {
       dispatch(setUserRole(e.target.value, data.id))
    }



    return (
        <Fragment>
            <FormControl className={classes.formControl}>
                <Select
                    name='role'
                    defaultValue={rowData.role}
                    onChange={e =>{onChangeField(e, rowData)}}
                >
                    {
                        roleListJson.map((role, i) =>
                            <MenuItem key={i} value={role.value}>{role.label}</MenuItem>
                        )}
                </Select>
            </FormControl>
        </Fragment>
    )

}
