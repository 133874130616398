//import noImage from '../../../images/no_image.jpg'

import {
    DELETE_PRODUCT_PENDING,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILED,
    CREATE_PRODUCT_PENDING,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILED,
    EDIT_PRODUCT_PENDING,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILED,
    EDIT_PRODUCT_IMAGE_PENDING,
    EDIT_PRODUCT_IMAGE_SUCCESS,
    EDIT_PRODUCT_IMAGE_FAILED,
    UPLOAD_IMAGE_PENDING,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILED,

    REMOVE_CLOUDINARY_IMAGE_PENDING,
    REMOVE_CLOUDINARY_IMAGE_SUCCESS,
    REMOVE_CLOUDINARY_IMAGE_FAILED,

    EDIT_FORM_CANCEL_REQUESTED,
    PRODUCTS_FROM_MODE_CHANGED,

    FILE_ADDED_TO_DROPZONE,
    CLEANED_DROPZONE
} from './constants'



const initialStateDropZone = {
    file: null,
    fileName: ''
}

const initialStateEditor = {
    editPendig: false,
    editEerror: '',
    imageUploadError: '',
}

const initialStateForm = {
    cancelAction: false,
    mode: 'show'
}

export const editor = (state = initialStateEditor, action = {}) => {
    switch (action.type) {
        case DELETE_PRODUCT_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case DELETE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case DELETE_PRODUCT_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case CREATE_PRODUCT_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case CREATE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case CREATE_PRODUCT_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case EDIT_PRODUCT_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_PRODUCT_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_PRODUCT_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case EDIT_PRODUCT_IMAGE_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_PRODUCT_IMAGE_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_PRODUCT_IMAGE_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case UPLOAD_IMAGE_PENDING:
            return Object.assign({}, state, { editPendig: false })
        case UPLOAD_IMAGE_SUCCESS:
            return Object.assign({}, state, { editPendig: false })
        case UPLOAD_IMAGE_FAILED:
            return Object.assign({}, state, { imageUploadError: action.payload })

        case REMOVE_CLOUDINARY_IMAGE_PENDING:
            return Object.assign({}, state, { editPendig: false })
        case REMOVE_CLOUDINARY_IMAGE_SUCCESS:
            return Object.assign({}, state, { editPendig: false })
        case REMOVE_CLOUDINARY_IMAGE_FAILED:
            return Object.assign({}, state, { imageUploadError: action.payload })

        default:
            return state;
    }
}


export const form = (state = initialStateForm, action = {}) => {
    switch (action.type) {
        case EDIT_FORM_CANCEL_REQUESTED:
            return Object.assign({}, state, { cancelAction: true })
        case PRODUCTS_FROM_MODE_CHANGED:
            return { ...state, mode: action.payload };
        default:
            return Object.assign({}, state)
    }
}

export const imageDropZone = (state = initialStateDropZone, action = {}) => {
    switch (action.type) {
        case FILE_ADDED_TO_DROPZONE:
            return { ...state, file: action.payload, fileName: action.payload.name };
        case CLEANED_DROPZONE:
            return { ...state, file: action.payload, fileName: '' };

        default:
            return Object.assign({}, state)
    }
}