import React from 'react';
import { Box } from '@material-ui/core';
import {Link} from 'react-router-dom'

const HomeButton = ({ description, cardImage, goTo }) => {
  return (
    <div className="flex items-center grow br4-ns br2 ba bw2 b--light-blue bg-light-blue h4-ns h3-m h2 w-30-ns">
      
      <Link to={goTo} className="flex items-center justify-center h-100 w-100" style={{ textDecoration: "none" }}>
        <div className="h-100 flex items-center justify-center ">
          <Box display={{ xs: 'none', sm: 'block' }}>
            <img
              className="w3-l w2-m mw3-ns mw1"
              src={cardImage}
              alt="card-tilit"
            ></img>
          </Box>
        </div>
        <div className="flex justify-center pl3-l pl1">
          <p className="f2-l f3-m f6 b tc">{description}</p>
        </div>
      </Link>
    </div>
  );
}

export default HomeButton;
