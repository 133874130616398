export const SIGNIN_PENDING = 'SIGNIN_PENDING'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'

export const VALIDATE_TOKEN_PENDING = 'VALIDATE_TOKEN_PENDING'
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS'
export const VALIDATE_TOKEN_FAILED = 'VALIDATE_TOKEN_FAILED'

export const SIGNUP_PENDING = 'SIGNUP_PENDING'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED =  'SIGNUP_FAILED'

export const SIGNOUT_PENDING = 'SIGNOUT_PENDING'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_FAILED = 'SIGNOUT_FAILED'

export const VALIDATE_RESET_TOKEN_PENDING = 'VALIDATE_RESET_TOKEN_PENDING'
export const VALIDATE_RESET_TOKEN_SUCCESS = 'VALIDATE_RESET_TOKEN_SUCCESS'
export const VALIDATE_RESET_TOKEN_FAILED = 'VALIDATE_RESET_TOKEN_FAILED'

export const SEND_RESET_EMAIL_PENDING = 'SEND_RESET_EMAIL_PENDING'
export const SEND_RESET_EMAIL_SUCCESS = 'SEND_RESET_EMAIL_SUCCESS'
export const SEND_RESET_EMAIL_FAILED = 'SEND_RESET_EMAIL_FAILED'

export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
