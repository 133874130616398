import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@material-ui/core/styles';
import bannerText1 from './../images/g4006.png';
import bannerImage1 from './../images/banner-img.jpg';

const useStyles = makeStyles(theme => ({
    // style rule
    carousel: {
        [theme.breakpoints.up('xs')]: {
            height: "200px",
        },
        [theme.breakpoints.up('sm')]: {
            height: "40vh",
        },
        [theme.breakpoints.up('md')]: {
            height: "50vh",
        },
        width: "100%"
    },
    text_area: {
            height:"100%",
            position: "absolute",
            right: 0,
            top: 0,
            [theme.breakpoints.up('xs')]: {
                width:"100%",
            },
            [theme.breakpoints.up('sm')]: {
                width:"75%",
            },
    },
    image: {

        [theme.breakpoints.up('xs')]: {
            width:"100%",
        },
        [theme.breakpoints.up('sm')]: {
            width:"60%",
        },
        [theme.breakpoints.up('md')]: {
            width:"50%",
        },
        backgroundColor: 'white'

    },
    block: {
        [theme.breakpoints.up('xs')]: {
            width:"0%",
        },
        [theme.breakpoints.up('sm')]: {
            width:"40%",
        },
        [theme.breakpoints.up('md')]: {
            width:"50%",
        },
       backgroundColor: "#59b2ff"
    },
}));

export default function HomeCarousel() {
    const classes = useStyles();

    const carousel = <Carousel
        showArrows={true}
        dynamicHeight={false}
        autoPlay
        interval={5000}
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        stopOnHover={false}
        transitionTime={500}
        emulateTouch
    >
        <div className={classes.carousel}>
            <div className='flex flex-row h-100'>
                <div className={classes.image}>
                    <img src={bannerImage1} alt='banner' className="h-100"/>
                </div>
                <div className={classes.block}/>
            </div>
            <div className={classes.text_area} >
                <img src={bannerText1} alt='banner' className="h-100"/>
            </div>
        </div>

    </Carousel>

    return (
        <div>
            {carousel}
        </div>
    )
}