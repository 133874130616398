// eslint-disable-next-line
import React from 'react';
//router support
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history'
//redux support
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
    products

} from './reducers';

import {
    editor, form,
    imageDropZone
} from './components/ProductsEditor/_redux/reducers';

import {
    userEditor,
    users
} from './components/Users/_redux/reducers';

import {
    session
} from './components/Login/_redux/reducers';

import {
    drawer
} from './components/ProductsView/redux/reducers';




//pass down the props to the connected containers
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
//used to allow async actions in redux
import thunkMiddleware from 'redux-thunk';
//components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import ProductsView from './components/ProductsView/Products';

import Home from './components/HomePage/Home';
import DrawerLayout from './components/Drawer';

import ProductsEditor from './components/ProductsEditor/ProductsEditor';
import UsersEditor from './components/Users/UsersEditor';
//style
import './index.css';
import 'tachyons';
//default
import * as serviceWorker from './serviceWorker';
//material ui
import { CssBaseline } from '@material-ui/core'
import { SignUp, SignIn, RequestPasswordReset, ValidatePasswordResetToken, ResetPassword } from './components/Login'



//Constants
const logger = createLogger();
const rootReducer = combineReducers(
    {
        products,
        editor,

        userEditor,
        session,
        users,

        imageDropZone,

        form,
        drawer
    })
//const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger))

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunkMiddleware, logger),
    //applyMiddleware(...logger),
    // other store enhancers if any
));


ReactDOM.render(
    <Provider store={store}><Router history={history}>
        <CssBaseline />
        <Navbar></Navbar>
        <DrawerLayout></DrawerLayout>
        <Switch>
            <Route exact path='/' component={Home} />
            {/* <Route path='/home' component={Home}/> */}
            <Route path='/products/view' component={ProductsView} />
            <Route path='/products/editor' component={ProductsEditor} />
            <Route path='/users/editor' component={UsersEditor} />
            <Route path='/signup' component={SignUp} />
            <Route path='/signin' component={SignIn} />
            <Route path='/password/reset/request' component={RequestPasswordReset} />
            <Route path='/password/reset/validate' component={ValidatePasswordResetToken} />
            <Route path='/password/reset/update' component={ResetPassword} />
            
        </Switch>
        <Footer></Footer>
    </Router></Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
