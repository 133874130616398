import nav_logo from '../../images/icons/logo_lacost_textonly.png';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from "react-redux";
import { toggleMobileOpen } from './../ProductsView/redux/actions'
//import { signOut } from './../Login/redux/actions'
import { Hidden } from '@material-ui/core';
import { useHistory } from "react-router-dom";

import AvatarBtn from './AvatarBtn';

export default function Navigation() {

    const history = useHistory();
    const currentPage = window.location.pathname
    const drawerWidth = currentPage === '/products' ? 240 : 0

    const useStyles = makeStyles((theme) => ({
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
            height: "65px"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        button:{
            textTransform: 'none',
            textSizeAdjust: true,
            fontSize: 18,
        }
    }));

    const classes = useStyles();

    const onSignIn = history.location.pathname === '/signin' ? true : false

    const dispatch = useDispatch();
    const mobileOpen = useSelector(state => state.drawer.mobileOpen);
    //! I will leave it in true in order to continue debbugig
    const logedIn = true

    const handleDrawerToggle = () => {
        dispatch(toggleMobileOpen(mobileOpen))
    };

    const onSignOut = () => {
        //dispatch(signOut())
    }

    const onClickHome = () => {
        history.push('/')
    }

    const onClickProducts = () => {
        history.push('/products/view')
    }

    const onClickSignIn = () => {
        history.push('/signin')
    }

    const onClickSignUp = () => {
        history.push('/signup')
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar>
                    <Box component="div" display={{ xs: 'block', sm: 'none' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.title}>
                        <img className="h2" alt="Logo" src={nav_logo}></img>
                    </Box>
                    <Box component="div" display="flex" alignItems="center">
                        <Box display={{ xs: 'none', sm: 'block', md: 'block' }}>
                            <Button onClick={onClickHome} className={classes.button}>Inicio</Button>
                            {/* <Button href="/products">Productos</Button> */}
                            <Button onClick={onClickProducts} className={classes.button}>Productos</Button>
                            <Hidden xsUp={logedIn}>
                                <Hidden xsUp={onSignIn}>
                                    <Button onClick={onClickSignIn} className={classes.button}>Ingresar</Button>
                                </Hidden>

                                <Hidden xsUp={!onSignIn}>
                                    <Button onClick={onClickSignUp} className={classes.button}>Registrarse</Button>
                                </Hidden>
                            </Hidden>
                        </Box>

                        <Hidden xsUp={!logedIn}>
                            <AvatarBtn signOut={onSignOut}></AvatarBtn>
                        </Hidden>
                    </Box>


                </Toolbar>
            </AppBar>
        </div>
    );
}