//import noImage from '../../../images/no_image.jpg'

import {
    GET_USERS_PENDING,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,

    EDIT_USER_PENDING,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED,

    DELETE_USER_PENDING,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,

    CREATE_USER_PENDING,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILED,

    SET_USER_ROLE_PENDING,
    SET_USER_ROLE_SUCCESS,
    SET_USER_ROLE_FAILED
} from './constants'

const initialStateUserEditor = {
    editPendig: false,
    editEerror: ''
}

const usersInit = {
    users:[],
    error: ''
}



export const users = (state = usersInit, action = {}) => {
    switch (action.type) {
        case GET_USERS_PENDING:
            return state;
        case GET_USERS_SUCCESS:
            return Object.assign({}, state, { users: action.payload, error: '' })
        case GET_USERS_FAILED:
            return Object.assign({}, state, { users: [], error: action.payload.message })
        default:
            return state;
    }
}



export const userEditor = (state = initialStateUserEditor, action = {}) => {
    switch (action.type) {
        case DELETE_USER_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case DELETE_USER_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case DELETE_USER_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case CREATE_USER_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case CREATE_USER_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case CREATE_USER_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case EDIT_USER_PENDING:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_USER_SUCCESS:
            return Object.assign({}, state, { editPendig: action.payload })
        case EDIT_USER_FAILED:
            return Object.assign({}, state, { editEerror: action.payload, editPendig: false })

        case SET_USER_ROLE_PENDING:
            return state;
        case SET_USER_ROLE_SUCCESS:
            return state;
        case SET_USER_ROLE_FAILED:
            return Object.assign({}, state, { editEerror: action.payload })

        default:
            return state;
    }
}