//EDITOR
export const DELETE_PRODUCT_PENDING = 'DELETE_PRODUCT_PENDING'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED'

export const CREATE_PRODUCT_PENDING = 'CREATE_PRODUCT_PENDING'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'

export const EDIT_PRODUCT_PENDING = 'EDIT_PRODUCT_PENDING'
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS'
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED'

export const EDIT_PRODUCT_IMAGE_PENDING = 'EDIT_PRODUCT_IMAGE_PENDING'
export const EDIT_PRODUCT_IMAGE_SUCCESS = 'EDIT_PRODUCT_IMAGE_SUCCESS'
export const EDIT_PRODUCT_IMAGE_FAILED = 'EDIT_PRODUCT_IMAGE_FAILED'

export const UPLOAD_IMAGE_PENDING = 'UPLOAD_IMAGE_PENDING'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED'

export const REMOVE_CLOUDINARY_IMAGE_PENDING = 'REMOVE_CLOUDINARY_IMAGE_PENDING'
export const REMOVE_CLOUDINARY_IMAGE_SUCCESS = 'REMOVE_CLOUDINARY_IMAGE_SUCCESS'
export const REMOVE_CLOUDINARY_IMAGE_FAILED = 'REMOVE_CLOUDINARY_IMAGE_FAILED'
//FORM
export const PRODUCTS_FROM_MODE_CHANGED = 'PRODUCTS_FROM_MODE_CHANGED'
export const EDIT_FORM_CANCEL_REQUESTED = 'EDIT_FORM_CANCEL_REQUESTED'
//FIELDSET
export const TOGGLE_ENABLE_FIELDSET = 'TOGGLE_ENABLE_FIELDSET'
export const NAME_FIELD_CHANGED = 'NAME_FIELD_CHANGED'
export const DESCRIPTION_FIELD_CHANGED = 'DESCRIPTION_FIELD_CHANGED'
export const CATEGORY_FIELD_CHANGED = 'CATEGORY_FIELD_CHANGED'
export const CLEAN_FIELDSET = 'CLEAN_FIELDSET'
export const SET_CATEGORY_VALUE = 'SET_CATEGORY_VALUE'
//DROPZONE
export const FILE_ADDED_TO_DROPZONE = 'FILE_ADDED_TO_DROPZONE'
//export const PRODUCT_IMAGE_URL_RETURNED = 'PREVIEW_IMAGE_URL_UPDATED'
export const CLEANED_DROPZONE = 'CLEANED_DROPZONE'
//TABLE
export const EDIT_MODE_TABLE_BLOCKED = 'EDIT_MODE_TABLE_BLOCKED'
export const SELECTED_PRODUCT_CHANNGED = 'SELECTED_PRODUCT_CHANNGED'
export const SELECTED_PRODUCT_REMOVED = 'SELECTED_PRODUCT_REMOVED'
export const SELECTED_PRODUCT_CLEARED = 'SELECTED_PRODUCT_CLEARED'

export const TOGGLE_SHOW_LIST_VALUE = 'TOGGLE_SHOW_LIST_VALUE'

export const EDIT_PENDING = true
export const EDIT_CONCLUDED = false













