import React, { useEffect, useState } from 'react';
//import { db, storage } from './../../index';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from './Dropzone';

import categoriesJson from '../../categories.json';
import Select from '@material-ui/core/Select'
import unitsJson from '../../units.json';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch } from "react-redux";
import { editProduct, cleanDropZone } from './_redux/actions'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const validationsInit = {
    noEdit: true,
    name: false,
    description: false,
    ref: false,
    units: false,
    category: false
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: "10px"
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function EditDialog(props) {
    const { open, handleClose, rowData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const file = useSelector(state => state.imageDropZone.file);

    const [formFields, setFormFields] = useState(rowData);
    const [isEmptyDropZone, setIsEmptyDropZone] = useState(true)
    const [validations, setValidations] = useState(validationsInit);

    useEffect(() => {
        setFormFields(rowData)
    }, [rowData]);

    useEffect(() => {
        if (file)
            setIsEmptyDropZone(false)
        else
            setIsEmptyDropZone(true)
    }, [file]);

    //DropZone Support



    //Progress bar support
    //const [currentProgress, setCurrentProgress] = useState(0);

    const handleEditProduct = () => {
        const { name, description, ref, units, category } = formFields;
        if (name !== "" && description !== "" && ref !== "" && units !== "" && category !== "") {

            console.log(formFields)
            dispatch(editProduct(formFields));
            setValidations(validationsInit);
            //setFormFields(formInit);
            //TODO: Maybe here we should clean up the form fields
            handleClose();
        } else {
            let { ...currentValidations } = validationsInit

            currentValidations.name = name === "" ? true : false;
            currentValidations.description = description === "" ? true : false;
            currentValidations.ref = ref === "" ? true : false;
            currentValidations.units = units === "" ? true : false;
            currentValidations.category = category === "" ? true : false;

            setValidations(currentValidations);
        }

    }

    function onChangeField(e) {
        setFormFields({ ...formFields, [e.target.name]: e.target.value })
        setValidations({ ...validations, [e.target.name]: false, 'noEdit': false })
    }

    return (

        <Dialog open={open} onClose={handleClose} disableBackdropClick>
            <DialogTitle>Editar un producto existente</DialogTitle>
            <DialogContent dividers>
                <form noValidate autoComplete="off">
                    <DialogContentText>
                        Aquí puede editar el producto.
                    </DialogContentText>
                    <TextField
                        error={validations.name}
                        required={validations.name}
                        defaultValue={rowData.name}
                        name='name'
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Nombre"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <TextField
                        error={validations.ref}
                        required={validations.ref}
                        defaultValue={rowData.ref}
                        name='ref'
                        margin="normal"
                        id="ref"
                        label="Referencia"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <FormControl variant="outlined" className={classes.formControl} error={validations.category} required={validations.category}>
                        <InputLabel>Categorías</InputLabel>
                        <Select
                            name='category'
                            label='Categoría'
                            defaultValue={rowData.category}
                            onChange={onChangeField}
                        >
                            {categoriesJson.map((category, i) =>
                                <MenuItem key={i} value={category.value}>{category.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} error={validations.units} required={validations.units}>
                        <InputLabel>Unidad</InputLabel>
                        <Select
                            name='units'
                            defaultValue={rowData.units}
                            onChange={onChangeField}
                            label='Unidad'
                        >
                            {
                                unitsJson.map((unit, i) =>
                                    <MenuItem key={i} value={unit.value}>{unit.label}</MenuItem>
                                )}
                        </Select>
                    </FormControl>
                    <Dropzone></Dropzone>
                    <div className={classes.root}>
                        <LinearProgress variant="determinate" value={0} />
                    </div>
                    <TextField
                        error={validations.description}
                        required={validations.description}
                        defaultValue={rowData.description}
                        margin="normal"
                        name='description'
                        label="Descripción"
                        type="text"
                        fullWidth
                        placeholder="Una breve descripción del producto"
                        size='medium'
                        multiline
                        rows='5'
                        onChange={onChangeField}
                    />
                </form>
            </DialogContent>
            <DialogActions>

                <Button onClick={() => {
                    setValidations(validationsInit);
                    //setFormFields(formInit);
                    //TODO: Should check here if setFormFields is necessary and how should it work.
                    dispatch(cleanDropZone())
                    handleClose();
                }} color="primary">
                    Cancelar
                    </Button>

                <Button disabled={(validations.noEdit && isEmptyDropZone)} onClick={handleEditProduct} color="primary">
                    Editar
                    </Button>
            </DialogActions>
        </Dialog>
    )
}
