import React, { useEffect, Fragment } from 'react';
import MaterialTable from 'material-table';
// import { db, fb } from '../../index'
import { makeStyles, Typography, Container } from '@material-ui/core'
// import { products } from '../../reducers';
import CreateDialog from './CreateDialog';
import EditDialog from './EditDialog';
import { useSelector, useDispatch } from 'react-redux';
import { requestProducts, updateProducts } from '../../actions'
import { deleteProduct } from './_redux/actions'
import { useHistory } from "react-router-dom";


export default function ProductEditor() {

    let history = useHistory();
const drawerWidth = history.location.pathname === '/products/editor' ? 240 : 0

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "2rem",
        maxWidth: "100%",
        flexGrow: 1

    },
    main: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey[300],
        minWidth: 300
    },
    detailPanel: {
        display: 'flex',
        justifyContent: 'start',
        maxWidth: '100%',
        padding: "20px"
    },
    imageDetail: {
        height: '100%',
        maxWidth: "100px",
        paddingTop: '10px'
    },
    descriptionDetail: {
        height: '100%',
        maxWidth: "100%",
        paddingLeft: "20px"
    }
    
}));

    const _products = useSelector(state => state.products.products)
    const [createOpen, setCreateOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [rowData, setRowData] = React.useState({
        category: "",
        description: "",
        id: null,
        image_url: "",
        name: "",
        ref: "",
        units: ""
    });
    const dispatch = useDispatch();

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleEditOpen = (rowData) => {
        setRowData(rowData)
        setEditOpen(true);
    };


    const handleClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
    };

    useEffect(() => {
        dispatch(requestProducts())
    }, [dispatch]); 

    //Array to set up the table columns
    const columns = [
        { title: 'Nombre', field: 'name' },
        { title: 'Referencia', field: 'ref' },
        { title: 'Categoría', field: 'category' },
        { title: 'Unidad', field: 'units' }
    ]

    const classes = useStyles();

    

    const productsTable =   <Fragment>
    <MaterialTable
        title="Editor de productos"
        columns={columns}
        data={_products}
        options={{
            actionsColumnIndex: -1
        }}
        detailPanel={[
            {
                tooltip: 'Ver detalles',
                render: rowData => {
                    return (
                        <div className={classes.detailPanel}>
                            <div className={classes.imageDetail}>
                                <img src={rowData.image_url} alt='product' />
                            </div>
                            <div className={classes.descriptionDetail}>
                                <Typography color='textPrimary' variant='h6' gutterBottom>Descripcion del producto:</Typography>
                                <Typography color='textSecondary' variant='body1' >{rowData.description !== "" ? rowData.description : 'Este producto aún no tiene una descripción'}</Typography>
                            </div>
                        </div>
                    )
                },

            }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
            {
                icon: 'add',
                tooltip: 'Crear un nuevo producto',
                isFreeAction: true,
                onClick: (event, rowData) => { handleCreateOpen() }
            },
            {
                icon: 'edit',
                tooltip: 'Editar producto',
                onClick: (event, rowData) => { handleEditOpen(rowData) }
            },
            {
                icon: 'delete',
                tooltip: 'Eliminar producto',
                onClick: (event, rowData) => {
                
                if(window.confirm("Confirmar eliminación")){
                    dispatch(deleteProduct(rowData.id))

                    let currentProduct = _products.find(product => product.id===rowData.id);
                    let updatedProducts = _products.filter(product => product !== currentProduct);
                        
                    dispatch(updateProducts(updatedProducts))
                }

                }
            },

        ]}
    />
    <CreateDialog open={createOpen} handleClose={handleClose}></CreateDialog>
    <EditDialog open={editOpen} rowData={rowData} handleClose={handleClose}></EditDialog>
    </Fragment>

    return (
        <div className={classes.main}>
            <Container className={classes.root} >
                {productsTable}
            </Container>
        </div>
    );
}

