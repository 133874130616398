import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({

    root:{
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      maxWidth: 550,
      minWidth: 250,
      boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, .2)',
      margin:"1rem"
    }

  ,
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function SearchBox(props) {
  const classes = useStyles();

  return (
    <Paper component="form" className= {classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Ingresa el nombre de un producto"
        inputProps={{ "aria-label": "search google maps" }}
        onChange={props.onChange}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}