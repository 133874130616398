import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import {validateToken, signOut} from '../Login/_redux/actions'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
      //Avatar
      blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    }
    //----
}));

export default function AvatarBtn(props) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const signedIn = useSelector(state => state.session.signedIn);

  useEffect(()=>{
    dispatch(validateToken)
  }
    ,[dispatch]
  )
 
  const name = window.sessionStorage.getItem('name')
  const surname = window.sessionStorage.getItem('surname')

  let nameInitial = !name? '': name[0]
  let surnameInitial = !surname? '': surname[0]

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogOut = (event) => {
    dispatch(signOut)
    history.push('/signin')
    handleClose(event)
  };

  const handleGoLogin = (event) => {
    history.push('/signin')
    handleClose(event)
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const avatar = signedIn ? <Avatar className={classes.blue}>{nameInitial + surnameInitial}</Avatar>:<Avatar></Avatar>
  const sessionBtn = signedIn ? <MenuItem onClick={handleLogOut}>Cerrar cesión</MenuItem> : <MenuItem onClick={handleGoLogin}>Iniciar sesión</MenuItem>
  

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >{avatar}
         
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                   {sessionBtn}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
