import React from 'react';
import HomeButton from './HomeButton';


function importAll(r) {
    return r.keys().map(r);
}
const images = importAll(require.context('../../../images/icons/', false, /\.(png|jpe?g|svg)$/));

export default function HomeButtons() {
    return (
            <div className="flex justify-around items-center flex-row bg-white pv5-l pv4 ph2 ">
                <HomeButton description={"Conócenos"} cardImage={images[0]} goTo='/info'></HomeButton>
                <HomeButton description={"Productos"} cardImage={images[1]} goTo='/products/view'></HomeButton>
                <HomeButton description={"Contáctanos"} cardImage={images[2]} goTo='/contact'></HomeButton>
            </div>
    );
}
