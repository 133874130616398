import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link as MuiLink } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from './_redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href="https://material-ui.com/">
        Distribuidora de productos Lacost S.A.S.
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    flexGrow: 1
  }

}));



export default function ResetPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formInit = {
    token: window.sessionStorage.getItem('token-reset'),
    password: "",
    passwordConfirm:""
  };

  const [form, setForm] = useState(formInit);
  const [lockPassUpdate, setLockPassUpdate] = useState(true);
  const pending = useSelector(state => state.session.pending)

  useEffect(()=>{
    if(form.password.length>0 || form.passwordConfirm.length>0){
      if(form.password === form.passwordConfirm){
        setLockPassUpdate(false)
      }else{
        setLockPassUpdate(true)
      }
    }
  },[form])

  function onChangeField(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const submitSingIn = () => {
    dispatch(resetPassword(form))
    setForm(formInit)
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reestablecer contraseña
        </Typography>
        <Hidden xsUp={!pending}>
          <CircularProgress />
        </Hidden>
        <form onSubmit={e => { e.preventDefault()}} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Contraseña"
            type="password"

            autoComplete="off"
            onChange={onChangeField}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="passwordConfirm"
            label="Confirmar contraseña"
            type="password"

            autoComplete="off"
            onChange={onChangeField}
          />
          <Button
            type="reset"
            disabled={lockPassUpdate||pending}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitSingIn}
          >
            Cambiar contraseña
                      </Button>
        </form>
      </div>
      <Box mt={8} className={classes.box}>
        <Copyright />
      </Box>
    </Container>
  );
}