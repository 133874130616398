import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link as MuiLink} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from "react-redux";
import {validateResetToken, sendTokenReset} from './_redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href="https://material-ui.com/">
        Distribuidora de productos Lacost S.A.S.
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  secret: {
    width: '50%', // Fix IE 11 issue.
  },
  submit: {
    width: '50%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0, 2),
  },
  resend: {
    width: '50%', // Fix IE 11 issue.
    margin: theme.spacing(0, 0, 2),
  },
  container:{
    flexGrow:1
  }

}));



export default function ValidatePasswordResetToken() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formInit = {
    secret: "",
    email: window.sessionStorage.getItem('email-reset')
  };
  
  const [form, setForm] = useState(formInit);
    
  

  const [countdown, setCountdown] = useState(30)

  const pending = useSelector(state => state.session.pending)
  
  function onChangeField(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
  }


  useEffect(()=>{
    if (countdown>0){
      setTimeout(()=>{
        setCountdown(countdown-1)
      }, 1000)
    }
    
  },[countdown])


  const submitValidateTokenReset = () => {
    dispatch(validateResetToken(form))
    setForm(formInit)
  }


  const submitEmailReset = () => {
    dispatch(sendTokenReset({email:window.sessionStorage.getItem('email-reset')}))
    setCountdown(30)
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Validar codigo de seguridad
        </Typography>
        <Hidden xsUp={!pending}>
          <CircularProgress />
        </Hidden>
        <form onSubmit={e => { e.preventDefault()}} className={classes.form} noValidate>
          <TextField
            className={classes.secret}
            variant="outlined"
            margin="normal"
            inputProps={{maxLength:6}}
            fullWidth
            id="secret_field"
            label="Codigo de seguridad"
            autoComplete="off"
            name="secret"
            autoFocus
            onChange={onChangeField}
          />
          
          <Button
            type="reset"
            fullWidth
            disabled={pending}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitValidateTokenReset}
          >
            verificar
          </Button>

          <Button
            type="reset"
            fullWidth
            disabled={countdown>0}
            variant="contained"
            color="primary"
            className={classes.resend}
            onClick={submitEmailReset}
          >
            {countdown>0?`volver a envíar ${countdown}`:'volver a envíar'}
          </Button>
        </form>
      </div>
      <Box mt={8} className={classes.box}>
        <Copyright />
      </Box>
    </Container>
  );
}