import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import {Link as MuiLink} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from "react-redux";
import {sendTokenReset} from './_redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href="https://material-ui.com/">
        Distribuidora de productos Lacost S.A.S.
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container:{
    flexGrow:1
  }

}));



export default function RequestPasswordReset() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formInit = {
    email: ""
  };
  
  const [form, setForm] = useState(formInit);
  const pending = useSelector(state => state.session.pending)

  function onChangeField(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const submitPasswordReset = () => {
    console.log(form)
    dispatch(sendTokenReset(form))
    setForm(formInit)
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
          
        </Avatar>
        
        <Typography component="h1" variant="h5">
          Recuperar Contraseña
        </Typography>
        <Hidden xsUp={!pending}>
          <CircularProgress />
        </Hidden>
        <form onSubmit={e => { e.preventDefault()}} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email_field"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={form.email}
            onChange={onChangeField}
          />
          <Button
            type="reset"
            disabled={pending}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitPasswordReset}
          >
            Eviar correo de recuperación
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/signin" variant="body2">
                Ya recordé mi contraseña
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"No tienes una cuenta? Registrate!"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} className={classes.box}>
        <Copyright />
      </Box>
    </Container>
  );
}