import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from "react-redux";
import { editUser } from './_redux/actions'

const validationsInit = {
    noEdit: true,
    name: false,
    email: false,
    surname: false
}

export default function EditDialog(props) {
    const { open, handleClose, rowData } = props;
    const dispatch = useDispatch();

    const [formFields, setFormFields] = useState(rowData);
    const [validations, setValidations] = useState(validationsInit);

    useEffect(() => {
        setFormFields(rowData)
    }, [rowData]);

    const handleEdit = () => {
        const { name, email, surname} = formFields;
        if (name !== "" && surname !== "" && email !== "") {
            console.log(formFields)
            dispatch(editUser(formFields));
            setValidations(validationsInit);
            handleClose();
        } else {
            let { ...currentValidations } = validationsInit

            currentValidations.name = name === "" ? true : false;
            currentValidations.surname = surname === "" ? true : false;
            currentValidations.email = email === "" ? true : false;

            setValidations(currentValidations);
        }

    }

    function onChangeField(e) {
        setFormFields({ ...formFields, [e.target.name]: e.target.value })
        setValidations({ ...validations, [e.target.name]: false, 'noEdit': false })
    }

    return (

        <Dialog open={open} onClose={handleClose} disableBackdropClick>
            <DialogTitle>Editar un producto existente</DialogTitle>
            <DialogContent dividers>
                <form noValidate autoComplete="off">
                    <DialogContentText>
                        Aquí puede editar el producto.
                    </DialogContentText>
                    <TextField
                        error={validations.name}
                        required={validations.name}
                        defaultValue={rowData.name}
                        name='name'
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Nombre"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <TextField
                        error={validations.surname}
                        required={validations.surname}
                        defaultValue={rowData.surname}
                        name='surname'
                        margin="normal"
                        id="surname"
                        label="Apellido"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                    <TextField
                        error={validations.email}
                        required={validations.email}
                        defaultValue={rowData.email}
                        margin="normal"
                        name='email'
                        label="Email"
                        type="text"
                        fullWidth
                        size='medium'
                        variant='outlined'
                        onChange={onChangeField}
                    />
                </form>
            </DialogContent>
            <DialogActions>

                <Button onClick={() => {
                    setValidations(validationsInit);
                    //TODO: Should check here if setFormFields is necessary and how should it work.
                    handleClose();
                }} color="primary">
                    Cancelar
                    </Button>

                <Button disabled={validations.noEdit} onClick={handleEdit} color="primary">
                    Editar
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

