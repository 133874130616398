import React from 'react';
import ProductCard from './Card';
import { Grid } from '@material-ui/core';

const ProductsList = (props) => {
    const { products } = props;

    return (
            <Grid container justify="center" spacing={2}>
                {products.map((product, i) =>
                    <Grid item key={i}>
                    <ProductCard
                        imagePath={product.image_url}
                        id={product.id}
                        name={product.name}
                        category={product.category}
                        description={product.description}
                    />
                    </Grid>
                )}
            </Grid>
    );
}
export default ProductsList;

