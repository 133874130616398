

export const GET_USERS_PENDING = 'GET_USERS_PENDING'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const EDIT_USER_PENDING = 'EDIT_USER_PENDING'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED'

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const CREATE_USER_PENDING = 'CREATE_USER_PENDING'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const SET_USER_ROLE_PENDING = 'SET_USER_ROLE_PENDING'
export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS'
export const SET_USER_ROLE_FAILED = 'SET_USER_ROLE_FAILED'



export const EDIT_PENDING = true
export const EDIT_CONCLUDED = false















