

import {
    REQUEST_PRODUCTS_PENDING,
    REQUEST_PRODUCTS_SUCCESS,
    REQUEST_PRODUCTS_FAILED,
    PRODUCTS_FILTER_UPDATED,
    SEARCH_FIELD_UPDATED,
    UPDATE_PRODUCTS
} from './constants'


const initialStateProducts = {
    products: [],
    isPendig: false,
    error: '',
    filteredProducts: [],
    searchField: '',
    isFiltered: false
}




export const products = (state = initialStateProducts, action = {}) => {
    switch (action.type) {
        case REQUEST_PRODUCTS_PENDING:
            return Object.assign({}, state, { isPendig: true })
        case REQUEST_PRODUCTS_SUCCESS:
            return Object.assign({}, state, { products: action.payload, isPendig: false })
        case REQUEST_PRODUCTS_FAILED:
            return Object.assign({}, state, { error: action.payload, isPendig: false })
        case PRODUCTS_FILTER_UPDATED:
            return Object.assign({}, state, { filteredProducts: action.payload.products, isFiltered: action.payload.isFiltered})
        case SEARCH_FIELD_UPDATED:
            return Object.assign({}, state, { searchField: action.payload})
        case UPDATE_PRODUCTS: 
            return Object.assign({}, state, {products: action.payload})
        default:
            return state;
    }
}




