//Products
const productImageSignature = '/api/product/image/signature'
const productImage = '/api/product/image'
const products = '/api/products'
const product = '/api/product' 

//Users
const users = '/api/users'
const user = '/api/user'
const userRole = '/api/user/role'

//login
const signUp = '/api/signup'
const signIn = '/api/signin'

//password reset
const sendTokenReset = '/api/password/token'
const passwordReset = '/api/password/reset'

//auth
const validateToken = '/api/user/validate'

//Host
const apiHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://web-lacost-api.herokuapp.com'


module.exports = {
    productImageSignature,
    productImage,
    products,
    product,
    users,
    user,
    userRole,
    signUp,
    signIn,
    apiHost,
    validateToken,
    sendTokenReset,
    passwordReset
}