import { requestProducts, updateSearchField } from '../../actions';
import CardsContainer from './ProductsList';
import SearchBox from './SearchBox';
import { Grid, Container, makeStyles, Typography } from '@material-ui/core';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

//material ui style

export default function Products() {
    const drawerWidth = window.location.pathname === '/products/view' ? 240 : 0
    const useStyles = makeStyles((theme) => ({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "2rem",
            maxWidth: "100%"
        },
        main: {
            flexGrow: 1,
            backgroundColor: theme.palette.grey[300],
            minWidth: 300
        },
        searcBox: {
            padding: "0",
            margin: "0",
        },
        title: {
            padding: "0",
            marginTop: "2rem"
        }
    }));


    //selectors
    const products = useSelector(state => state.products.products)
    const filteredProducts = useSelector(state => state.products.filteredProducts);
    const isPendig = useSelector(state => state.products.isPendig);
    const searchField = useSelector(state => state.products.searchField);
    const isFiltered = useSelector(state => state.products.isFiltered);
    //const error = useSelector(state => state.products.error)

    //dispatch
    const dispatch = useDispatch()

    //consts
    var _products = null;
    if (filteredProducts.length > 0) {
        _products = filteredProducts
    } else if (filteredProducts.length === 0 && isFiltered) {
        _products = []
    } else {
        _products = products
    }

    const filterProduct = _products.filter(product => {
        return product.name.toLowerCase().includes(searchField.toLowerCase());
    })

    //functions
    function searchBoxChenged(event) {
        dispatch(updateSearchField(event.target.value))
    }

    //hook useEffects
    useEffect(() => {
        dispatch(requestProducts());
    }, [dispatch])

    //style support
    const classes = useStyles();


    const matchedProducts = filterProduct.length > 0 ? <CardsContainer products={filterProduct}></CardsContainer> : <h2>Ops! Nada por aquí... prueba a buscar algo más</h2>

    //Const to be render 
    //TODO: shoudl be moved to an independent component.
    const productsArea = isPendig ?
        <div>Cargando productos</div>
        : products.length > 0 ?
            <div>
                <Grid container justify="center" className="pt4">
                    {matchedProducts}
                </Grid>
            </div>
            : <div>No hay productos disponibles...</div>



    return (
        <div className={classes.main}>
            <Container className={classes.root} >
                <Typography variant="h4" className={classes.title}>
                    Buscar
                        </Typography>
                <SearchBox className={classes.searcBox}
                    onChange={searchBoxChenged}
                ></SearchBox>
                {productsArea}
            </Container>
        </div>
    );
}