import React, { useEffect, Fragment } from 'react';
import MaterialTable from 'material-table';
import CreateDialog from './CreateDialog';
import EditDialog from './EditDialog';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, deleteUser } from './_redux/actions';
import { Container, makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import RowSelect from "./RoleSelect"


export default function MaterialTableDemo() {

    let history = useHistory();
    const drawerWidth = history.location.pathname === '/users/editor' ? 240 : 0
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "2rem",
            maxWidth: "100%",
            flexGrow: 1

        },
        main: {
            flexGrow: 1,
            backgroundColor: theme.palette.grey[300],
            minWidth: 300
        }
    }));


    const _users = useSelector(state => state.users.users)

    const [createOpen, setCreateOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [rowData, setRowData] = React.useState({
        id: null,
        email: "",
        name: "",
        surname: "",
        role: ""
    });
    const dispatch = useDispatch();

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleEditOpen = (rowData) => {
        setRowData(rowData)
        setEditOpen(true);
    };


    const handleClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
    };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);






    //Array to set up the table columns

    const classes = useStyles();



    const usersTable = <Fragment>
        <MaterialTable
            title="Editor de usuarios"
            columns={
                [
                    { title: 'Nombre', field: 'name' },
                    { title: 'Apellido', field: 'surname' },
                    { title: 'Email', field: 'email' },
                    {
                        field: 'role',
                        title: 'Role',
                        render: rowData => <RowSelect rowData={rowData}></RowSelect>
                    }
                ]
            }
            data={_users}
            options={{
                actionsColumnIndex: -1
            }}
            actions={[
                {
                    icon: 'add',
                    tooltip: 'Crear un nuevo usuario',
                    isFreeAction: true,
                    onClick: (e, rowData) => { handleCreateOpen() }
                },
                {
                    icon: 'edit',
                    tooltip: 'Editar usuario',
                    onClick: (e, rowData) => { handleEditOpen(rowData) }
                },
                {
                    icon: 'delete',
                    tooltip: 'Eliminar usuario',
                    onClick: (e, rowData) => {

                        if (window.confirm("Confirmar eliminación")) {
                            dispatch(deleteUser(rowData.id))
                        }

                    }
                },

            ]}
        />
        <CreateDialog open={createOpen} handleClose={handleClose}></CreateDialog>
        <EditDialog open={editOpen} rowData={rowData} handleClose={handleClose}></EditDialog>
    </Fragment>


    return (
        <div className={classes.main}>
            <Container className={classes.root} >
                {usersTable}
            </Container>
        </div>
    );

}

